import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"


const AboutWorkCulture = () => {
  return (
    <section className="about-work-culture">
        <Container>
            <Row className="justify-content-center">
                <Col md={12} className="text-center">
                <p className="section-tag">
                    OUR WORK CULTURE
                </p>
                <h2 className="sub-title">Work Hard. Play Harder.</h2>                    
                </Col>
                <Col lg={10} className="pt-5">
                    <Row>
                        <Col lg={12} className="mb-3">
                            <StaticImage src="../../images/about/work-culture-1.jpeg" alt="WowMakers Work Culture" placeholder="blurred" width={1364} height={800}/>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <StaticImage src="../../images/about/work-culture-2.jpeg" alt="WowMakers Work Culture" placeholder="blurred" width={674} height={674}/>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <StaticImage src="../../images/about/work-culture-3.jpeg" alt="WowMakers Work Culture" placeholder="blurred" width={674} height={674}/>
                        </Col>
                        <Col lg={12} className="mb-3">
                            <StaticImage src="../../images/about/work-culture-4.jpeg" alt="WowMakers Work Culture" placeholder="blurred" width={1364} height={800}/>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <StaticImage src="../../images/about/work-culture-5.jpeg" alt="WowMakers Work Culture" placeholder="blurred" width={674} height={674}/>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <StaticImage src="../../images/about/work-culture-6.jpeg" alt="WowMakers Work Culture" placeholder="blurred" width={674} height={674}/>
                        </Col>                                                                                                                                
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default AboutWorkCulture
