import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"


const AboutPeopleBehindUsLegalFinance = () => {
  return (
    <section className="people-behind-us">
        <Container>
            <Row className="justify-content-center">
                <Col md={12} className="text-center">
                <p className="section-tag">
                    PEOPLE BEHIND US
                </p>
                <h2 className="sub-title">Legal & Finance Advisors</h2>                    
                </Col>
                <Col lg={10}>
                    <Row>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/Anil.jpeg" alt="Anil Legal Team" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">Anil Xavier</p>
                                <p className="pbu-designation">Management Consultant</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/Vijayraj.jpeg" alt="Vijayraj Legal Team" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">VIJAYARAJ T</p>
                                <p className="pbu-designation">Legal Consultant</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/Manoj.jpeg" alt="Manoj Finance Consultant" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">MANOJ KUMAR</p>
                                <p className="pbu-designation">Finance Consultant</p>
                            </div>                    
                        </Col>                                
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default AboutPeopleBehindUsLegalFinance
