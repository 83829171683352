import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import '../../sass/global/testimonial-highlights.scss'

const AboutHighlightTestimonialsSony = () => {
  return (
    <section className="testimonial-highlight sony py-5">
      <Container>
        <Row className="justify-content-end">
          <Col lg={5} md={7}>
            <div className="t-content">
              <h2>
                “When we met the founders of WowMakers for the first time, I had a feeling that these guys have what it takes to build a world class design house. They lived upto my expectations and indeed became a strong brand in design space.”
              </h2>
              <p className="name">SONY JOY</p>
              <p className="designation">Head, Truecaller Pay</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutHighlightTestimonialsSony
