import React from "react"
import { Col, Container, Row } from "react-bootstrap"


const AboutOurInspiration = () => {
  return (
    <section className="our-inspiration py-0">
        <Container>
            <Row>
                <Col md={12} className="text-center">
                <p className="section-tag">
                    DESIGN THINKING
                </p>
                <h2 className="sub-title">Our Inspiration</h2>                    
                </Col>
            </Row>
        </Container>
        <div className="testimonial-highlight don-norman mt-5 py-5">
            <Container>
                <Row>
                    <Col lg={5} md={7}>
                        <div className="t-content">
                            <h2>"Good design is actually a lot harder to notice than poor design, in part because good designs fit our needs so well that the design is invisible"</h2>
                            <p className="name">DON NORMAN</p>
                            <p className="designation">Human-Centered Design Pioneer</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </section>
  )
}

export default AboutOurInspiration
