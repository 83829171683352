import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import '../../sass/global/testimonial-highlights.scss'

const AboutHighlightTestimonialsSanjay = () => {
  return (
    <section className="testimonial-highlight sanjay py-5">
      <Container>
        <Row>
          <Col lg={5} md={7}>
            <div className="t-content">
              <h2>
                “I’m a proud investor as well as an early adopter of their skillsets- from UX Design and Explainer Videos to Branding and Communication Design- for the past seven years; and loved the work and value gained.”
              </h2>
              <p className="name">SANJAY VIJAYAKUMAR</p>
              <p className="designation">Angel Investor, WowMakers</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutHighlightTestimonialsSanjay
