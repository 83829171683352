import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ButtonIcon from "../icon/buttonIcon"
import "../../sass/global/work-culture.scss"

const AboutCareers = () => {
  return (
    <section className="work-culture about-careers">
      <Container>
        <Row>
          <Col md={8} lg={5}>
            <div className="content">
              <span className="section-tag">CAREERS</span>
              <h2 className="title">
                Why join the navy when you can be a pirate?
              </h2>
              <p className="description">
                Do you truly believe good design can change the world for better? There’s a great chance you can become a WowMaker!
              </p>
              <Link to="/careers/" class="btn wowaction-btn">
                <ButtonIcon />
                <span>Careers & Opportunities</span>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutCareers
