import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"


const AboutPeopleBehindUsMentors = () => {
  return (
    <section className="people-behind-us">
        <Container>
            <Row className="justify-content-center">
                <Col md={12} className="text-center">
                <p className="section-tag">
                    PEOPLE BEHIND US
                </p>
                <h2 className="sub-title">Our Mentors & Advisors</h2>                    
                </Col>
                <Col lg={10}>
                    <Row>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/Sanjay.jpeg" alt="SANJAY VIJAYAKUMAR, Founder, MobME & Startup Village " placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">SANJAY VIJAYAKUMAR</p>
                                <p className="pbu-designation">Founder, MobME & Startup Village</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/Sony-Joy.jpeg" alt="SONY JOY, Head, TrueCaller Pay" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">SONY JOY</p>
                                <p className="pbu-designation">Head, TrueCaller Pay</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/santhosh-t-k.jpeg" alt="Santhosh T Kuruvilla, Founder, Spark Capital & STK Global" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">Santhosh T Kuruvilla</p>
                                <p className="pbu-designation">Founder, Spark Capital & STK Global</p>
                            </div>                    
                        </Col>                                                
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/Sajin.jpg" alt="SAJIN KUNHAMBU, Technology Advisor" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">SAJIN KUNHAMBU</p>
                                <p className="pbu-designation">Dell, Sapient & TCS</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/Vivek-Steve-Francis.jpeg" alt="VIVEK STEVE FRANCIS, Founder, Hatio Innovations" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">VIVEK STEVE FRANCIS</p>
                                <p className="pbu-designation">Founder, Hatio Innovations</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/Manu-Scariya.jpeg" alt="MANU SCARIA, CEO, MobME Wireless" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">MANU SCARIA</p>
                                <p className="pbu-designation">CEO, MobME Wireless</p>
                            </div>                    
                        </Col>                                                        
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default AboutPeopleBehindUsMentors
