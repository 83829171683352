import React from "react"
import { Col, Container, Row } from "react-bootstrap"


const AboutHumanCenteredDesign = () => {
  return (
    <section className="about-us-philosophy">
        <Container>
            <div className="about-us-intros">
                <Row>
                    <Col md={5}>
                        <h2 className="au-title">
                            Human Centered Design
                        </h2>
                    </Col>
                    <Col md={6} className="offset-md-1">
                        <div className="content-desc">
                            <p className="au-content">
                                Being a human-centered designer is about believing that as long as you stay grounded in what you’ve learned from people, your team can arrive at new solutions that the user needs. We’re optimists and makers, experimenters and learners, we empathize and iterate, and we look for inspiration in unexpected places.
                            </p>
                            <p className="au-content">
                                We believe that a solution is out there and that by keeping focused on the people we’re designing for and asking the right questions, we’ll get there together.                                
                            </p>                           
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </section>
  )
}

export default AboutHumanCenteredDesign
