import React from "react"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import AboutMain from "../../components/about/aboutMain"
import AboutWhoWeAre from "../../components/about/aboutWhoWeAre"
import AboutWhatWeDo from "../../components/about/aboutWhatWeDo"
import AboutValues from "../../components/about/aboutValues"
import AboutHumanCenteredDesign from "../../components/about/aboutHumanCenteredDesign"
import AboutOurInspiration from "../../components/about/aboutOurInspiration"
import AboutPeopleBehindUsMentors from "../../components/about/aboutPeopleBehindUsMentors"
import AboutHighlightTestimonialsSanjay from "../../components/about/aboutHighlightTestimonialsSanjay"
import AboutPeopleBehindUsLegalFinance from "../../components/about/aboutPeopleBehindUsLegalFinance"
import AboutHighlightTestimonialsSony from "../../components/about/aboutHighlightTestimonialsSony"
import AboutTeam from "../../components/about/aboutTeam"
import AboutManagementTeam from "../../components/about/aboutManagementTeam"
import AboutHighlightTestimonialsVivek from "../../components/about/aboutHighlightTestimonialsVivek"
import AboutWorkCulture from "../../components/about/aboutWorkCulture"
import AboutAchievements from "../../components/about/aboutAchievements"
import AboutMedia from "../../components/about/aboutMedia"
import AboutCareers from "../../components/about/aboutCareers"
import HomeForm from "../../components/home/homeForm"
import DirectContact from "../../components/global/directContact"
import SeoHead from "../../components/global/seoHead"


import "../../sass/pages/about.scss"
import AboutFeaturedImage from "../../../static/about-us/About-Us-Featured.jpeg"


const About = () => {
    return(
        <Layout>
        <Seo
            bclass="about"
            bid="about"
        ></Seo>
        <ScrollIndicationHeightZero />
        <AboutMain />
        <AboutWhoWeAre />
        <AboutWhatWeDo />
        <AboutValues />
        <AboutHumanCenteredDesign />
        <AboutOurInspiration />
        <AboutPeopleBehindUsMentors />
        <AboutHighlightTestimonialsSanjay />
        <AboutPeopleBehindUsLegalFinance />
        <AboutHighlightTestimonialsSony />
        <AboutTeam />
        <AboutManagementTeam />
        <AboutHighlightTestimonialsVivek />
        <AboutWorkCulture />
        <AboutAchievements />
        <AboutMedia />
        <AboutCareers />
        <HomeForm page="about"/>
        <DirectContact />            
        </Layout>
    )
  }
  
  export const Head = () => (
      <>
        <SeoHead
          ogImage={AboutFeaturedImage}
          title="Meet The WowMakers"
          description="We're a 12-year-old digital agency that create digital experiences for top brands and businesses across the globe."
        />
      </> 
  )
  export default About