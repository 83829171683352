import React from "react"
import { Col, Container, Row } from "react-bootstrap"


const AboutValues = () => {
  return (
    <section className="values-and-beliefs">
        <Container>
            <div className="about-us-intros">
                <Row>
                    <Col md={4}>
                        <h2 className="au-title vab">
                            Our culture and values
                        </h2>
                    </Col>
                    <Col md={6} className="offset-md-2">
                        <div className="content-desc">
                            <p className="au-content text-white">
                                WowMakers is more than just a business. We're a group of passionate individuals with a shared mission and values.
                            </p>
                            <ul className="au-content list-au-content text-white">
                                <li>Always, always look at the big picture</li>
                                <li>Be curious, seek learning</li>
                                <li>Put your heart and soul in what you do</li>
                                <li>Have empathy. Be a good listener</li>
                                <li>Direct communication, no bullshit</li>
                                <li>Work hard. Play harder!</li>
                            </ul>                            
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </section>
  )
}

export default AboutValues
