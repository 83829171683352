import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import '../../sass/global/testimonial-highlights.scss'

const AboutHighlightTestimonialsVivek = () => {
  return (
    <section className="testimonial-highlight vivek py-5">
      <Container>
        <Row>
          <Col lg={5} md={7}>
            <div className="t-content">
              <h2>
                “Design and technology may change over time, but human psychology - our desires, emotions, and motivations stay the same. So whatever we do, we focus on that one thing that has never changed: People!”
              </h2>
              <p className="name">VIVEK RAGHAVAN</p>
              <p className="designation">Founder & CEO</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutHighlightTestimonialsVivek
