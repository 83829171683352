import React from "react"
import MainLogo from "../global/mainLogo"
import { Col, Container, Row } from "react-bootstrap"

import "../../sass/global/main.scss"

const AboutMain = () => {
  return (
    <div className="main">
      <Container>
        <Row>
          <Col md={12} lg={7} xl={6}>
            <div className="intro">
              <p className="service-label">THE PEOPLE</p>
              <h1 className="title">About WowMakers</h1>
              <p className="description">
                We work with companies and organizations of diverse vertical, mission and geographies. Why? Because we’re curious, we like to learn and we love challenges. Know more about us here.
              </p>
            </div>
          </Col>
          <MainLogo />
        </Row>
      </Container>
    </div>
  )
}

export default AboutMain
