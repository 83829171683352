import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"


const AboutTeam = () => {
  return (
    <section className="people-behind-us">
        <Container>
            <Row className="justify-content-center">
                <Col md={12} className="text-center">
                <p className="section-tag">
                    THE TEAM
                </p>
                <h2 className="sub-title">Meet The WowMakers</h2>                    
                </Col>
                <Col lg={10}>
                    <Row>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/Hafsal.jpg" alt="Hafsal, Head of UX" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">HAFSAL</p>
                                <p className="pbu-designation">Head of UX</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/Swathi.jpg" alt="Swathi Lakshmy, Business Development Executive" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">Swathi Lakshmy</p>
                                <p className="pbu-designation">Business Development Executive</p>
                            </div>                    
                        </Col>                        
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/Angela.jpg" alt="Angela Bennet, Project Manager UI UX" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">Angela Bennet</p>
                                <p className="pbu-designation">Project Manager UI UX</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/Reshma.jpg" alt="RESHMA SUKUMARAN, Project Manager" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">RESHMA SUKUMARAN</p>
                                <p className="pbu-designation">Senior Project Manager</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/Namitha.jpg" alt="Namita S, Business Analyst" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">Namita S</p>
                                <p className="pbu-designation">Business Analyst</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/Bibin.jpeg" alt="BIBIN BASHEER, Finance Manager" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">BIBIN BASHEER</p>
                                <p className="pbu-designation">Finance Manager</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/Sneha.jpg" alt="Sneha K T K, HR Manager" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">Sneha K T K</p>
                                <p className="pbu-designation">HR Manager</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/Pradeep.jpg" alt="PRADEEP TV, Developer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">PRADEEP TV</p>
                                <p className="pbu-designation">Developer</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/Vicky.jpg" alt="VIGNESH I, Visual Designer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">VIGNESH I </p>
                                <p className="pbu-designation">Visual Designer</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/athul.png" alt="Athul P, Visual Designer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">Athul P </p>
                                <p className="pbu-designation">Visual Designer</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/Abhinand.jpg" alt="ABHINAND T P, Visual Designer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">ABHINAND T P</p>
                                <p className="pbu-designation">Visual Designer</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/Sreejith.jpg" alt="SREEJITH P S, Visual Designer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">SREEJITH P S</p>
                                <p className="pbu-designation">Visual Designer</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/Indhu.jpg" alt="INDHUPRIYA, Visual Designer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">INDHUPRIYA</p>
                                <p className="pbu-designation">Visual Designer</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/John.jpg" alt="JOHN, Interaction Designer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">JOHN</p>
                                <p className="pbu-designation">Interaction Designer</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/Rakesh.jpg" alt="RAKESH, Interaction Designer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">RAKESH</p>
                                <p className="pbu-designation">Interaction Designer</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/Afsar.jpg" alt="AFSAR C, Creative Director" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">AFSAR C</p>
                                <p className="pbu-designation">Creative Director</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/Joel.jpg" alt="JOEL MATHEW, Animation Director" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">JOEL MATHEW</p>
                                <p className="pbu-designation">Animation Director</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/Rinoy.jpg" alt="RINOY SEBASTIAN, Director of Photography" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">RINOY SEBASTIAN</p>
                                <p className="pbu-designation">Director of Photography</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/Bijy.jpg" alt="BIJY BENNY, Sr Script Writer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">BIJY BENNY</p>
                                <p className="pbu-designation">Sr Script Writer</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/shahin.png" alt="Shahin M S, Script Writer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">Shahin M S</p>
                                <p className="pbu-designation">Script Writer</p>
                            </div>                    
                        </Col>                        
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/Gireesh.jpg" alt="GIRISH S, Animator" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">GIRISH S</p>
                                <p className="pbu-designation">Animator</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/Pooja.jpg" alt="POOJA, Illustrator" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">POOJA</p>
                                <p className="pbu-designation">Illustrator</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/Abhirami.jpg" alt="ABHIRAMI, Illustrator" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">ABHIRAMI</p>
                                <p className="pbu-designation">Illustrator</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/abhijith-anil.png" alt="Abhijith Anil, Illustrator" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">Abhijith Anil</p>
                                <p className="pbu-designation">Illustrator</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/ramziya.jpg" alt="Ramziya, Content Writer | WowMakers" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">Ramziya</p>
                                <p className="pbu-designation">Content Writer</p>
                            </div>                    
                        </Col>                                                
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/Dilshith.jpg" alt="DILSHITH DINESH, Admin Executive" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">DILSHITH DINESH</p>
                                <p className="pbu-designation">Admin Executive</p>
                            </div>                    
                        </Col>                                                                                                                                                                                                                                
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default AboutTeam
