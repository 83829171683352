import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"


const AboutMedia = () => {
  return (
    <section className="about-work-culture pt-0">
        <Container>
            <Row className="justify-content-center">
                <Col md={12} className="text-center">
                    <p className="section-tag">
                        IN THE LIMELIGHT
                    </p>
                    <h2 className="sub-title">WowMakers In The Media</h2>                    
                </Col>
                <Col lg={10} className="pt-5">
                    <StaticImage src="../../images/about/wm-in-media.png" alt="WowMakers in Media" placeholder="blurred" width={1509} height={727}/>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default AboutMedia
