import React from "react"
import { Col, Container, Row } from "react-bootstrap"


const AboutWhoWeAre = () => {
  return (
    <section className="who-we-are">
        <Container>
            <div className="about-us-intros">
                <Row>
                    <Col md={6}>
                        <h2 className="au-title">
                            Making digital awesome. Since 2011.
                        </h2>
                    </Col>
                    <Col md={6}>
                        <div className="content-desc">
                            <p className="au-content">
                                Founded in 2011, we’re a close-knit team of designers, engineers, marketers, researchers and story tellers driven by the passion for crafting intuitive and delightful digital experiences.
                            </p>
                            <p className="au-content">
                                Putting people and their needs first, we propose unique and innovative creative solutions. We use design-thinking to translate observation into insights, and those insights into products and services that could make the lives of people easier and better.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </section>
  )
}

export default AboutWhoWeAre
