import React from "react"
import { Col, Container, Row } from "react-bootstrap"


const AboutWhatWeDo = () => {
  return (
    <section className="what-we-do">
        <Container>
            <div className="about-us-intros">
                <Row>
                    <Col md={5}>
                        <h2 className="au-title">
                            Why we do what we do?
                        </h2>
                    </Col>
                    <Col md={6} className="offset-md-1">
                        <div className="content-desc">
                            <p className="au-content">
                                We believe good design is good business. Our mission is to bring order to chaos through intuitive designs, innovative ideas and creative solutions.
                            </p>
                            <p className="au-content">
                                We take great pride and satisfaction in seeing our work bringing positive changes and convenience to the lives of people all across the globe.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </section>
  )
}

export default AboutWhatWeDo
