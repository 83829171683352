import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"


const AboutManagementTeam = () => {
  return (
    <section className="people-behind-us management-team">
        <Container>
            <Row className="justify-content-center">
                <Col md={12} className="text-center">
                <p className="section-tag">
                    LEADERSHIP
                </p>
                <h2 className="sub-title">Management Team</h2>                    
                </Col>
                <Col lg={10}>
                    <Row>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/vivek.jpeg" alt="VIVEK RAGHAVAN, Founder" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">VIVEK RAGHAVAN</p>
                                <p className="pbu-designation">Founder & CEO</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/jaseem.jpeg" alt="JASEEM TS, Co-Founder & CEO" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">JASEEM Thayal Shareef</p>
                                <p className="pbu-designation">Co-Founder & CMO</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details last text-center">
                                <StaticImage src="../../images/about/ralu.jpeg" alt="RALU RAJAN MADATHIL, Chief Marketing Officer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">RALU RAJAN MADATHIL</p>
                                <p className="pbu-designation">Co-Founder & CTO</p>
                            </div>                    
                        </Col>
                        <Col md={4}>
                            <div className="pbu-details first text-center">
                                <StaticImage src="../../images/about/Deepu.jpg" alt="DEEPAK PRABHAKARAN, Chief Operating Officer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">DEEPAK PRABHAKARAN</p>
                                <p className="pbu-designation">Co-Founder & COO</p>
                            </div>                    
                        </Col>                                                        
                        <Col md={4}>
                            <div className="pbu-details middle text-center">
                                <StaticImage src="../../images/about/Jithesh.jpg" alt="JITHESH LAKSHMAN, Visual Designer" placeholder="blurred" width={398} height={398}/>
                                <p className="pbu-name">JITHESH LAKSHMAN</p>
                                <p className="pbu-designation">Director of Visual Design</p>
                            </div>                    
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default AboutManagementTeam
